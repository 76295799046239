
// import "./styles/header.module.css";

import styles from './styles/header.module.css';

import {Logo} from "./Logo";
import {ButtonContainer} from "./ButtonContainer";
import UserAvatar from "./UserAvatar";




export const Header = ({isLoggedIn, client, onLogout, isAdmin, onAdminLogout}) => {




    return (
        <div className={styles.header}>
            <Logo />
            <ButtonContainer isLoggedIn={isLoggedIn} client={client} onLogout={onLogout} isAdmin={isAdmin} onAdminLogout={onAdminLogout}/>
            {/*<UserAvatar altText={'USER'}/>*/}
        </div>
    )
}
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";

import styles from "./styles/projectPage.module.css";

import panel1 from './Operation Dark Resurrection Poster.jpg';
import panel2 from './Operation Dark Resurrection-Pitch Desk2.png';
import panel3 from './Operation Dark Resurrection-Pitch Desk3.png';
import panel4 from './Operation Dark Resurrection-Pitch Desk4.png';
import panel5 from './Operation Dark Resurrection-Pitch Desk5.png';
import panel6 from './Operation Dark Resurrection-Pitch Desk6.png';
import panel7 from './Operation Dark Resurrection-Pitch Desk7.png';
import panel8 from './Operation Dark Resurrection-Pitch Desk8.png';
import panel9 from './Operation Dark Resurrection-Pitch Desk9.png';
import panel10 from './Operation Dark Resurrection-Pitch Desk10.png';
import panel11 from './Operation Dark Resurrection-Pitch Desk11.png';
import panel12 from './Operation Dark Resurrection-Pitch Desk12.png';
import panel13 from './Operation Dark Resurrection-Pitch Desk13.png';

// import audioFile from './Millenium.mp3';
export const ProjectPageJungle = () => {

    useEffect(() => {

        // const mainTheme = new Audio(audioFile);
        // mainTheme.play();

    }, []);

    return (
        <div className={styles.projectPage}>
            <div style={{background: "white"}}>
                <img src={panel1}
                     style={{width: "70%", background: "white", borderStyle: "solid", borderWidth: "thick"}}
                     alt="Panel 1"/>

            </div>
            <img src={panel2} alt="Panel 2"/>
            <img src={panel3} alt="Panel 3"/>
            <img src={panel4} alt="Panel 4"/>
            <img src={panel5} alt="Panel 5"/>
            <img src={panel6} alt="Panel 6"/>
            <img src={panel7} alt="Panel 7"/>
            <img src={panel8} alt="Panel 8"/>
            <img src={panel9} alt="Panel 9"/>
            <img src={panel10} alt="Panel 10"/>
            <img src={panel11} alt="Panel 11"/>
            <img src={panel12} alt="Panel 12"/>
            <img src={panel13} alt="Panel 12"/>

        </div>
    );
}
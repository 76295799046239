

import styles from "./styles/loginSection.module.css";
import {Link} from "react-router-dom";
import {useState} from "react";
import {useNavigate} from "react-router";
import toast, {Toaster} from "react-hot-toast";


export const LoginSection = ({loginCallback}) => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();


    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();

        const loginUrl = `https://www.api.mgw-productions.com/api/client/login`;
        const loginPayload = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email, password})
        };

        try {
            const loginResponse = await fetch(loginUrl, loginPayload);
            const loginData = await handleResponse(loginResponse);

            if(!loginData || !loginData.token) {
                toast.error("Login ist fehlgeschlagen. Falsche Kombination von E-Mail und Password");
                return;
            }

            console.log(loginData);
            // remove any previous if any
            localStorage.removeItem('token');
            localStorage.removeItem('clientID');
            localStorage.removeItem('client');
            localStorage.removeItem('pitchdecks');


            // Save token to local storage
            localStorage.setItem('token', loginData.token);
            localStorage.setItem('clientID', loginData.clientID);
            localStorage.setItem('pitchdecks', loginData.pitchdecks);


            const clientUrl = `https://www.api.mgw-productions.com/api/restricted/clients/${loginData.clientID}`;
            const clientPayload = {
                method: 'GET',
                // credentials: "include",
                headers: {'Authorization': "Bearer " + localStorage.getItem("token")}
            };
            const clientResponse = await fetch(clientUrl, clientPayload);
            const clientData = await handleResponse(clientResponse);

            if (!clientData || clientData.message === "Access not allowed.") {
                navigate('/login');
                return;
            }

            localStorage.setItem('client', clientData);
            loginCallback(clientData, loginData.token);
            navigate('/dashboard');

        } catch (error) {
            console.error('Error:', error);
            toast.error("Login ist fehlgeschlagen. Versuche es bitte erneut.");
        }
    }

    const handleResponse = async (response) => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');

        if (!contentType || contentType.indexOf('application/json') === -1) {
            throw new Error(`HTTP error! Expected JSON but received ${contentType}`);
        }

        return await response.json();
    }

    return (
        <div className={styles.loginSection}>

            <div className={styles.title}>Login</div>
            <form onSubmit={handleSubmit}>
                <div className={styles.formInput}>
                    <label htmlFor='email'>E-Mail</label>
                    <input type='email' name='email' placeholder='Email' required={true} onChange={e => setEmail(e.target.value)}/>
                </div>

                <div className={styles.formInput}>
                    <label htmlFor='password'>Passwort</label>
                    <input type='password' name='password' placeholder='Passwort' onChange={e=> setPassword(e.target.value)}/>
                </div>

                <input type="submit" value="Login" className={styles.button} />
            </form>
            {/*<Link to='/passwordReset'>*/}
            {/*    <div className={styles.passwordRecovery}>Passwort vergessen?</div>*/}
            {/*</Link>*/}
            <Toaster/>
        </div>
    )
}
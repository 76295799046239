
import styles from "./styles/footer.module.css"
import {Link} from "react-router-dom";
export const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.footerText}><Link to='/iusme'>IusME</Link></div> | <div className={styles.footerText}><Link to='/kontakt'>Kontakt</Link></div> | <div className={styles.footerText}><Link to='/impressum'>Impressum</Link></div>
        </div>
    )
}



import styles from "./styles/body.module.css";
import {Outlet} from "react-router-dom";

export const Body = () => {
    return (
        <div className={styles.body}>

            <Outlet/>
        </div>
    )
}

import styles from "./styles/dashboardSection.module.css";
import React, {useEffect, useState} from "react";
import {Link, redirect} from "react-router-dom";
import {useNavigate} from "react-router";

import posterMinotaur from "./Poster-1v9_small.png";
import posterZeppelin from "./Zeppelin Poster-Recovered-Recovered.png";
import posterKingdom from "./Poster3-reduced.png";
import posterResurrection from "./Operation Dark Resurrection Poster.jpg";
import posterMendelssohn from "./Fingals Echo-Poster.jpg";
export const DashboardSection = ({client}) => {

    const [modules, setModules] = useState();
    const navigate = useNavigate();


    const [subscribedPitchdecks, setSubscribedPitchdecks] = useState([]);

    useEffect(() => {
        const fetchModules = async () => {
            const token = localStorage.getItem('token');
            const clientID = localStorage.getItem('clientID');


            if (token && token !== "") {
                const modulePayload = {
                    method: 'GET',
                    credentials: "include",
                    headers: {'Authorization': "Bearer " + localStorage.getItem("token")}
                };
                const response = await fetch(
                    'https://www.iusme.ch/api/restricted/modules', modulePayload);
                const data = await response.json();
                if (data.message === "Access not allowed.") {
                    navigate('/login');
                } else {
                    console.log(data);
                    setModules(data);
                }
            } else {
                navigate('/login');
            }



        }

        // const fetchSubscribedPitchdecks = async () => {
        //     const token = localStorage.getItem('token');
        //     const clientID = localStorage.getItem('clientID');
        //
        //
        //     if (token && token !== "") {
        //         const payload = {
        //             method: 'GET',
        //             credentials: "include",
        //             headers: {'Authorization': "Bearer " + localStorage.getItem("token")}
        //         };
        //         const response = await fetch(
        //             `https://www.api.mgw-productions.com/api/restricted/clients/${clientID}/subscribedpitchdecks`, payload);
        //         const data = await response.json();
        //         if (data.message === "Access not allowed.") {
        //             navigate('/login');
        //         } else {
        //             console.log(data);
        //             if (Array.isArray(data)) {
        //                 let allModules = data.reduce((acc, subscription) => {
        //                     return acc.concat(subscription.modules);
        //                 }, []);
        //                 setSubscribedModules(allModules);
        //             } else {
        //                 console.log('data is not an Array');
        //             }
        //         }
        //     } else {
        //         navigate('/login');
        //     }
        //
        //
        //
        // }

        const array = localStorage.getItem("pitchdecks").split(',');

        setSubscribedPitchdecks(array);

        //fetchModules();
        //fetchSubscribedModules();


    }, []);


    const handleClick = (event) => {
        const id = event.currentTarget.id;

        let url;
        switch (id) {
            case '1':
                url = '/project/minotaur';
                break;
            case '2':
                url = '/project/andromeda';
                break;
            case '3':
                url = '/project/kingdom';
                break;
            case '4':
                url = '/project/resurrection';
                break;
            case '5':
                url = '/project/mendelssohn';
                break;
            default:
                console.log('Invalid div id')
        }
        if (url) navigate(url);
    };

    return (
        <div className={styles.dashboardSection}>
            <div style={styles.dashboardTitle}></div>
            <div className={styles.title}>Welcome, {client?.first_name}!</div>
            <div className={styles.subtitle}>We are very happy to present you the following titles:</div>
            {/*<div className={styles.searchBar}>*/}
            {/*    <img className={styles.searchIcon} src={searchIcon} />*/}
            {/*    <input type='text' className={styles.searchInput} placeholder='Suche Video nach Suchbegriff...'/>*/}
            {/*</div>*/}

            <div className={styles.pitchdeckContainer}>

                {
                    subscribedPitchdecks.map((item, index) => {
                        switch (item.trim()) {
                            case '1':
                                return (
                                    <div className={styles.pitchdeck} onClick={handleClick} id="1">
                                        <div className={styles.title}>The Wrath of the Minotaur</div>
                                        <div className={styles.poster}>
                                            <img src={posterMinotaur} alt={"poster"}></img>
                                        </div>
                                        <div className={styles.specContainer}>
                                            <div className={styles.spec}><span>Genre:</span> Action-Adventure / History
                                                /
                                                Romance
                                            </div>
                                            <div className={styles.spec}><span>Status:</span> Packaging / Financing
                                            </div>
                                        </div>
                                    </div>
                                )
                            case '2':
                                return (
                                    <div className={styles.pitchdeck} onClick={handleClick} id="2">
                                        <div className={styles.title}>Codeword: Andromeda</div>
                                        <div className={styles.poster}>
                                            <img src={posterZeppelin} alt={"poster"}></img>
                                        </div>
                                        <div className={styles.specContainer}>
                                            <div className={styles.spec}><span>Genre:</span> Action-Adventure / History
                                                /
                                                Romance
                                            </div>
                                            <div className={styles.spec}><span>Status:</span> Packaging / Financing
                                            </div>
                                        </div>
                                    </div>
                                )
                            case '3':
                                return (
                                    <div className={styles.pitchdeck} onClick={handleClick} id="3">
                                        <div className={styles.title}>The Secret of the Lost Kingdom</div>
                                        <div className={styles.poster}>
                                            <img src={posterKingdom} alt={"poster"}></img>
                                        </div>
                                        <div className={styles.specContainer}>
                                            <div className={styles.spec}><span>Genre:</span> Action-Adventure / Romance
                                            </div>
                                            <div className={styles.spec}><span>Status:</span> Packaging / Financing
                                            </div>

                                        </div>
                                    </div>
                                )
                            case '4':
                                return (
                                    <div className={styles.pitchdeck} onClick={handleClick} id="4">
                                        <div className={styles.title}>Operation: Dark Resurrection</div>
                                        <div className={styles.poster}>
                                            <img src={posterResurrection} alt={"poster"}></img>

                                        </div>
                                        <div className={styles.specContainer}>
                                            <div className={styles.spec}><span>Genre:</span> Action-Adventure / Thriller
                                                /
                                                Horror
                                            </div>
                                            <div className={styles.spec}><span>Status:</span> Packaging / Financing
                                            </div>
                                        </div>
                                    </div>
                                )
                            case '5':
                                return (
                                    <div className={styles.pitchdeck} onClick={handleClick} id="5">
                                        <div className={styles.title}>Fingal's Echo</div>
                                        <div className={styles.poster}>
                                            <img src={posterMendelssohn} alt={"poster"}></img>

                                        </div>
                                        <div className={styles.specContainer}>
                                            <div className={styles.spec}><span>Genre:</span> Biographical Drama / Music
                                                /
                                                Historical
                                            </div>
                                            <div className={styles.spec}><span>Status:</span> Packaging / Financing
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                    })
                }


                {/*{*/}
                {/*    modules?.map((module) => {*/}
                {/*        const currentModuleId = module.id;*/}
                {/*        const included = subscribedModules?.some(*/}
                {/*            (subscribedModule) => subscribedModule.id === currentModuleId*/}
                {/*        );*/}
                {/*        const link = '/modules/' + module.id;*/}
                {/*        return included ? (*/}
                {/*                <Link to={link}>*/}
                {/*                    <Module title={module.name} description={module.description} color={module.display_color} restricted={false}/>*/}
                {/*                </Link>*/}

                {/*            ) :*/}
                {/*        <Module title={module.name} description={module.description} color={module.display_color} restricted={true}/>*/}

                {/*    })*/}
                {/*}*/}
                {/*    /!*<Video video={"Test"} />*!/*/}
                {/*    /!*<Module title={'Öffentliches Recht I'} description={"Dieses Modul enthält Lernvideos, Podcasts, Mini-Games zu den Themen: Grundlegender Staatsaufbau in der Schweiz, Grundrechte"} color={"aquamarine"}/>*!/*/}
                {/*    /!*<Module title={'Privatrecht I '} description={"Dieses Modul enthält Lernvideos, Podcasts, Mini-Games zu den Themen: Personenrecht"} color={'#4caf50'}/>*!/*/}
                {/*    /!*<Module title={'Strafrecht AT '} description={"Dieses Modul enthält Lernvideos, Podcasts, Mini-Games zu den Themen: Strafrecht AT"} color={'#106dd0'}/>*!/*/}
                {/*    /!*<Module title={'Öffentliches Recht II'} description={"Dieses Modul enthält Lernvideos, Podcasts, Mini-Games zu den Themen: Allgemeines Verwaltungsrecht"} color={'#ff8800'}/>*!/*/}

            </div>
        </div>
    )
}



import styles from "./styles/buttonContainer.module.css"
import {Button} from "./Button";

import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import UserAvatar from "./UserAvatar";
import {Navigate, useNavigate} from "react-router";

export const ButtonContainer = ({isLoggedIn, client, onLogout, isAdmin, onAdminLogout}) => {

    const navigate = useNavigate();
    const handleToDashboard = () => {
        navigate("/dashboard");
    }

    return (
        <div className={styles.buttonContainer}>
            {!isLoggedIn ? (
                <>
                    <Link to='/login'>
                        <Button title={"Login"}/>
                    </Link>
                </>
            ) : (
                isAdmin ? (
                    <>
                    <p>ADMIN-AREA</p> <Button onClickCallback={onAdminLogout} title="Logout" />
                    </>
                    ) :
                    <>
                        <Button title={"Back to Dashboard"} onClickCallback={handleToDashboard}></Button>
                        {/*<div className={styles.button} onClick={handleToDashboard}>Back to Dashboard</div>*/}
                        <UserAvatar client={client} onLogout={onLogout}/>
                    </>

    )
}
</div>
    )
}
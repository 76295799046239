import React, {useEffect, useState} from 'react';

import styles from "./styles/userAvatar.module.css";
import {Link} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router";

const UserAvatar = ({client, onLogout }) => {
    const [showMenu, setShowMenu] = useState(false);
    const hidden = { display: showMenu ? 'block' : 'none'};
    const avatarStyle = styles.avatarStyle;
    const avatarStyleClicked = styles.avatarStyleClicked;
    const appliedStyleAvatar = showMenu ? avatarStyleClicked : avatarStyle;

    const menuItemStyle = styles.menuItemStyle;
    const menuItemStyleClicked = styles.menuItemStyleClicked;

    const [firstLetterFirstName, setFirstLetterFirstName] = useState();
    const [firstLetterLastName, setFirstLetterLastName] = useState();




    useEffect(() => {


    }, [client]);



    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className={styles.userAvatar}>
            <div onClick={toggleMenu} className={appliedStyleAvatar}>{client?.first_name?.charAt(0)}{client?.last_name?.charAt(0)}</div>
            <div className={styles.menuStyle} style={hidden}>
                {/*<Link to={`/myaccount/${client.id}`}>   <div className={styles.menuItemStyle} id={styles.top} style={hidden} onFocusCapture={toggleMenu} onClick={toggleMenu}>Mein Konto</div></Link>*/}
                {/*<Link to='/login'>  */}
                    <div className={styles.menuItemStyle} id={styles.bottom} style={hidden} onFocusCapture={toggleMenu} onClick={onLogout}>Logout</div>
                {/*</Link>*/}
            </div>
        </div>
    );
}

export default UserAvatar;
import React, {useEffect, useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router";

import styles from "./styles/projectPage.module.css";

import panel1 from './Fingals Echo-Poster.jpg';
import panel2 from './Mendelssohn pitch deck2.png';
import panel3 from './Mendelssohn pitch deck3.png';
import panel4 from './Mendelssohn pitch deck4.png';
import panel5 from './Mendelssohn pitch deck5.png';
import panel6 from './Mendelssohn pitch deck6.png';
import panel7 from './Mendelssohn pitch deck7.png';
import panel8 from './Mendelssohn pitch deck8.png';
import panel9 from './Mendelssohn pitch deck10.png';
import panel10 from './Mendelssohn pitch deck11.png';
import panel11 from './Mendelssohn pitch deck12.png';
import panel12 from './Mendelssohn pitch deck13.png';

import audioFile from './Mendelssohn-Hebriden.mp3';
export const ProjectPageMendelssohn = () => {

    useEffect(() => {

        const mainTheme = new Audio(audioFile);
        mainTheme.play();

    }, []);


    return (
        <div className={styles.projectPage}>

            <div style={{background: "white"}}>
                <img src={panel1}
                     style={{width: "70%", background: "white", borderStyle: "solid", borderWidth: "thick"}}
                     alt="Panel 1"/>

            </div>
            <img src={panel2} alt="Panel 2"/>
            <img src={panel3} alt="Panel 3"/>
            <img src={panel4} alt="Panel 4" />
            <img src={panel5} alt="Panel 5" />
            <img src={panel6} alt="Panel 6" />
            <img src={panel7} alt="Panel 7" />
            <img src={panel8} alt="Panel 8" />
            <img src={panel9} alt="Panel 9" />
            <img src={panel10} alt="Panel 10" />
            <img src={panel11} alt="Panel 11" />
            <img src={panel12} alt="Panel 12" />
        </div>
    );
}
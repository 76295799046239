
import {BrowserRouter as Router, Routes, Route, Outlet, Link, redirect} from "react-router-dom";

import './App.css';
import {Header} from "./Header";
import {Body} from "./Body";
import {Footer} from "./Footer";
import {LoginSection} from "./LoginSection";
import {DashboardSection} from "./DashboardSection";
import {ProjectPageMinotaur} from "./ProjectPageMinotaur";
import {ProjectPageJungle} from "./ProjectPageJungle";
import {ProjectPageZeppelin} from "./ProjectPageZeppelin";
import {ProjectPageLostKingdom} from "./ProjectPageLostKingdom";

//import {BackendDashboard} from "./BackendDashboard";
//import {BackendLoginSection} from "./BackendLoginSection";

import {useEffect, useState} from "react";

import {jwtDecode} from "jwt-decode";


import {Navigate, useNavigate, useParams} from "react-router";
import toast from "react-hot-toast";
import {ProjectPageMendelssohn} from "./ProjectPageMendelssohn";


export function RestrictedAdminRoute({isAdminLoggedIn}) {
  if (!isAdminLoggedIn) {
    return <Navigate to='/backend/login' />;
  }

  return <Outlet />;
}
export function RestrictedRoute({children, isLoggedIn}) {
  if (!isLoggedIn) {
    return <Navigate to='/login'/>;
  } else {
    return <Outlet />;
  }

}


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState();
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState();
  const [clientID, setClientID] = useState();
  const [token, setToken] = useState();
  const [client, setClient] = useState();
  const [stripeIdentification, setStripeIdentification] = useState();

  const [admin, setAdmin] = useState();
  const [adminID, setAdminID] = useState();
  const [adminToken, setAdminToken] = useState();

  const [count, setCount] = useState(0);

  const [redirectTo, setRedirectTo] = useState('');

  const handleResponse = async (response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentType = response.headers.get('content-type');

    if (!contentType || contentType.indexOf('application/json') === -1) {
      throw new Error(`HTTP error! Expected JSON but received ${contentType}`);
    }

    return await response.json();
  }


  const handleLogout = async (redirectToLogin = true) => {
    try {
      // handle register logout in database
      const logoutUrl = `https://www.api.mgw-productions.com/api/client/logout`;
      const logooutPayload = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({clientID})
      };

      const logoutResponse = await fetch(logoutUrl, logooutPayload);
      const logoutData = await handleResponse(logoutResponse);

      if (!logoutData || !logoutData.token) {
        toast.error("Logout ist fehlgeschlagen. Falsche Kombination von E-Mail und Password");
        return;
      }
    }
       catch (error) {
      console.error('Error:', error);

    }

    localStorage.removeItem('token');
    localStorage.removeItem('clientID');
    localStorage.removeItem('client');
    localStorage.removeItem('stripeIdentification');
    localStorage.removeItem('pitchdecks');

    setClientID(null);
    setToken(null);
    setIsLoggedIn(false);



      if (redirectToLogin) {
        return <Navigate to='/login'/>;

        // setRedirectTo('login');
      }


  }

    const handleLogin = async (client, token) => {


      if (token !== undefined && client !== undefined && token !== null && client !== null) {
        setIsLoggedIn(true);
        setClientID(client.id);
        setClient(client);
        setStripeIdentification(client.stripe_identification);
        setToken(token);

        // check if client has any subscription at all

        const clientID = localStorage.getItem('clientID');

        setRedirectTo('dashboard');

      } else {
        return;
      }
    }

    const handleAdminLogin = (admin, adminToken) => {
      if (adminToken !== undefined && admin !== undefined && adminToken !== null && admin !== null) {
        setIsLoggedIn(true);
        setIsAdminLoggedIn(true);
        setAdminID(admin.id);
        setAdmin(admin);
        setAdminToken(adminToken);
      }
    }

    const handleAdminLogout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('adminID');
      setIsLoggedIn(false);
      setIsAdminLoggedIn(false);
      setAdminID(null);
      setAdmin(null);
      setAdminToken(null);

      return <Navigate to='/backend/login'/>;
    }

    function checkTokenExpiration() {
      const token = localStorage.getItem('token');

      if (!token) {
        return false;
      }

      let decodedToken;

      try {
        decodedToken = jwtDecode(token);
      } catch (error) {
        return false;
      }

      // JWT's `exp` claim is a timestamp in seconds since the epoch.
      const expirationDate = new Date(decodedToken.exp * 1000);
      console.log("Expiration Date: " + expirationDate);
      console.log("Date now: " + new Date());

      if (expirationDate > new Date()) {
        return false;
      } else {
        return true;
      }
    }


    useEffect(() => {
      const increment = () => {
        // simulate the querying, for example, checking the status of server
        const isTokenExpired = checkTokenExpiration();
        if (isTokenExpired === true && !isAdminLoggedIn) {
          handleLogout();
        } else if (isTokenExpired === true && isAdminLoggedIn) {
          handleAdminLogout();
        }
        setCount(prev => prev + 1) // You can't use count state because it's will trigger useEffect and cause infinite loop. You need to use callback to update from previous value
      }

      //const intervalId = setInterval(increment, 20000) // setInterval return interval id

      return () => {
        // Clear interval using intervalId
        // This function run when component unmount
        //clearInterval(intervalId)
      }

    }, [])

    useEffect(() => {
      if (redirectTo) {
        // Clears the redirectTo path after navigating
        setRedirectTo('');
      }
    }, [redirectTo]); // Runs whenever `redirectTo` changes

  useEffect(() => {
    const onBeforeUnload = (ev) => {

      //#############
      console.log("SOME CODE HERE");
      //#############

      handleLogout();
      ev.returnValue = "Anything you wanna put here!";
      return "Anything here as well, doesn't matter!";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);
    return (
        <div className="App">

          <Router>

            <Header isLoggedIn={isLoggedIn} client={client} onLogout={handleLogout}
                    isAdmin={isAdminLoggedIn} onAdminLogout={handleAdminLogout}/>

            <Routes>
              <Route path='/' element={<Body/>}>
                {/*<Route index element={<LandingSection/>}/>*/}
                <Route index={true} element={<LoginSection loginCallback={handleLogin}/>}/>
                <Route path='/login' element={<LoginSection loginCallback={handleLogin}/>}/>

                <Route path='*' element={<RestrictedRoute isLoggedIn={isLoggedIn}/>}>
                  <Route path='dashboard' element={<DashboardSection client={client}/>}/>
                  <Route path='project/minotaur' element={<ProjectPageMinotaur/>}/>
                  <Route path='project/resurrection' element={<ProjectPageJungle/>}/>
                  <Route path='project/andromeda' element={<ProjectPageZeppelin/>}/>
                  <Route path='project/kingdom' element={<ProjectPageLostKingdom/>}/>
                  <Route path='project/mendelssohn' element={<ProjectPageMendelssohn/>}/>
                </Route>

                {/*<Route path='/backend/login' element={<BackendLoginSection loginCallback={handleAdminLogin}/>}/>*/}

                {/*<Route path="/backend/*" element={<RestrictedAdminRoute isAdminLoggedIn={isAdminLoggedIn}/>}>*/}

                {/*  <Route path="dashboard" element={<BackendDashboard/>}/>*/}

                {/*</Route>*/}

              </Route>
            </Routes>
            <Footer/>

          </Router>
        </div>
    );
  }

export default App;
